// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCurrentUserInfo, isUserLoggedIn } from '../services/auth';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [userRole, setUserRole] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Assume getCurrentUserInfo() and isUserLoggedIn() are asynchronous functions
                if (await isUserLoggedIn()) {
                    const userInfo = await getCurrentUserInfo();
                    setUserRole(userInfo.role);
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <AuthContext.Provider value={{ userRole, isLoggedIn, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
