import {getCurrentUserInfo} from "./services/auth";


const roleConfig = {
    "/settings/manageusers": ["ADMIN"],
    "/settings/manageproducts": ["ADMIN",],
    "/settings/managesupplier": ["ADMIN"],
    "/settings/managecustomer": ["ADMIN"],
    "/settings": ["ADMIN"],
    "/productList": ["ADMIN", "STOREMANAGER"],
    "/orderOverview": ["ADMIN", "WAREHOUSEUSER"],
    "/deliveryNoteOverview": ["ADMIN", "WAREHOUSEUSER", "STOREMANAGER"],
};

export const isAuthorized = () => {
    let url = window.location.pathname;
    let userRole = getCurrentUserInfo().role;
    const authorizedRoles = roleConfig[url] || [];
    return authorizedRoles.includes(userRole);
};

export const isAuthorizedForNavbar = (currentUrl) => {
    const userInfo = getCurrentUserInfo();
    const userRole = userInfo ? userInfo.role : null;
    const authorizedRoles = roleConfig[currentUrl] || [];
    return authorizedRoles.includes(userRole);
}


