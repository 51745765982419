// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { isAuthorized } from '../roleConfig';


const PrivateRoute = () => {
    
    // Check if the user has the required role for this URL
    const isUserAuthorized = isAuthorized();

    if (!isUserAuthorized) {
        return <><h1 style={{ color: "red", textAlign: "center" }}>You are Unauthorized to access this page</h1></>;
        // You can customize the unauthorized behavior (e.g., redirect to an error page)
        // return <Navigate to="/error" replace />;
    }

    return <Outlet></Outlet>;
};

export default PrivateRoute;
