import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Routes, Route, useNavigate, useLocation} from "react-router-dom";

import {NavbarProvider} from './components/contexts/NavbarContext';
import {AuthProvider, useAuth} from './components/contexts/AuthContext';
import PrivateRoute from './components/Auth/PrivateRoute';

//Lazy loading
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const ProductList = lazy(() => import('./components/ProductList/ProductList'));
const OrderOverview = lazy(() => import('./components/OrderOverview/OrderOverview'));
const DeliveryNote = lazy(() => import('./components/DeliveryNotesAndInvoice/DeliveryNotesAndInvoice'));
const Settings = lazy(() => import('./components/Settings/Settings'));
const ManageProducts = lazy(() => import('./components/Settings/ManageProducts/ManageProducts'));
const ManageSupplier = lazy(() => import('./components/Settings/ManageSupplier/ManageSupplier'));
const ManageUsers = lazy(() => import('./components/Settings/ManageUsers/ManageUsers'));
const ManageCustomer = lazy(() => import('./components/Settings/ManageCustomer/ManageCustomer'));
const Login = lazy(() => import('./components/Auth/Login'));
const Logout = lazy(() => import('./components/Auth/Logout'));
const ProtectedGate = lazy(() => import('./components/ProtectedGate'));
const Home = lazy(() => import('./components/Home'));
const ErrorPage = lazy(() => import('./components/ErrorPage'));

function App() {


    return (
        <Router>
            <AuthProvider>
                <AppContent/>
            </AuthProvider>
        </Router>
    );
}

function AppContent() {
    const {userRole, isLoggedIn, isLoading} = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <NavbarProvider>
                <Suspense fallback={<div>Loading page components...</div>}>
                    <Navbar/>
                    <div className="page-content">
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/logout" element={<Logout/>}/>

                            {/* Protect routes within this element */}
                            <Route element={<ProtectedGate/>}>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/orderOverview" element={<OrderOverview/>}/>
                                <Route path="/settings" element={<Settings/>}/>

                                {/* Further protection or role-based routes can be nested here */}
                                <Route element={<PrivateRoute/>}>
                                    <Route path="/productList" element={<ProductList/>}/>
                                    <Route path="/deliveryNoteOverview" element={<DeliveryNote/>}/>
                                    <Route path="/settings/manageproducts" element={<ManageProducts/>}/>
                                    <Route path="/settings/managesupplier" element={<ManageSupplier/>}/>
                                    <Route path="/settings/manageusers" element={<ManageUsers/>}/>
                                    <Route path="/settings/managecustomer" element={<ManageCustomer/>}/>
                                </Route>

                                <Route path="*" element={<ErrorPage/>}/>
                            </Route>
                        </Routes>
                    </div>
                </Suspense>
            </NavbarProvider>
            <Footer/>
        </>
    );
}

export default App;